import loadable from "@loadable/component";
import { LoadingScreen } from "./components/utils";
import { Navigate } from "react-router-dom";
import AuthGaurd from './utils/AuthGaurd';
import { element } from "prop-types";



//APP
const Home = loadable(() => import("./pages/Home" /* webpackChunkName: "home" */))
const Dashboard = loadable(() => import("./pages/dashboard/Dashboard" /* webpackChunkName: "dashboard" */))

//AUTH
const Login = loadable(() => import("./pages/auth/Login" /* webpackChunkName: "login" */))
const ForgetPassword = loadable(() => import("./pages/auth/ForgetPassword" /* webpackChunkName: "forgotpassword" */))
const ResetPassword = loadable(() => import("./pages/auth/ResetPassword" /* webpackChunkName: "resetpassword" */))
const SignUp = loadable(() => import("./pages/auth/SignUp" /* webpackChunkName: "signup" */))
const SubscriptionPayment = loadable(() => import("./pages/auth/SubscriptionPayment" /* webpackChunkName: "subscriptionpayment" */))

//MANAGE
const ActivityAttendee = loadable(() => import("./pages/manage/ActivityAttendee" /* webpackChunkName: "activityattendee" */))
const CourseAttendee = loadable(() => import("./pages/manage/CourseAttendee.jsx" /* webpackChunkName: "courseattendee" */))
const ActivityOhReport=loadable(()=>import("./pages/manage/Order_Report"/* webpackChunkName: "activityohreport" */))
const ContentCreator = loadable(() => import("./pages/manage/ContentCreator" /* webpackChunkName: "contentcreator" */))
const Clinic = loadable(() => import("./pages/manage/Clinic" /* webpackChunkName: "clinic" */))
const Lms = loadable(() => import("./pages/manage/Lms" /* webpackChunkName: "lms" */))
const ResearchInstitution = loadable(() => import("./pages/manage/ResearchInstitution" /* webpackChunkName: "researchinstitution" */))
const PatientGroup = loadable(() => import("./pages/manage/PatientGroup" /* webpackChunkName: "patientgroup" */))
const PatientGroupSA = loadable(() => import("./pages/manage/PatientGroupSA" /* webpackChunkName: "patientgroupsa" */))
const Patient = loadable(() => import("./pages/manage/Patient" /* webpackChunkName: "patient" */))
const Clinician = loadable(() => import("./pages/manage/Clinician" /* webpackChunkName: "clinician" */))
const SurveyGroup = loadable(() => import("./pages/manage/SurveyGroup" /* webpackChunkName: "surveygroup" */))
const Researcher = loadable(() => import("./pages/manage/Researcher" /* webpackChunkName: "researcher" */))
const Courses = loadable(() => import("./pages/manage/Courses" /* webpackChunkName: "courses" */))
const ReviewARFActivities = loadable(() => import("./pages/manage/ReviewARFActivities" /* webpackChunkName: "review" */))
const Proposal = loadable(() => import("./pages/manage/Proposal" /* webpackChunkName: "proposal" */))
const SurveyCA = loadable(() => import("./pages/manage/SurveyCA" /* webpackChunkName: "surveyca" */))
const SurveySA = loadable(() => import("./pages/manage/SurveySA" /* webpackChunkName: "surveysa" */))
const MarketActivity = loadable(() => import("./pages/manage/MarketActivity" /* webpackChunkName: "marketactivity" */))
const ReviewMarketActivity = loadable(() => import("./pages/manage/ReviewMarketActivity" /* webpackChunkName: "reviewmarketactivity" */))
const MarketActivityPreview = loadable(() => import("./pages/manage/MarketActivityPreview" /* webpackChunkName: "marketactivitypreview" */))
const PublishedCourse = loadable(() => import("./pages/manage/PublishedCourse" /* webpackChunkName: "publishedcourse" */))
const AssignCourse = loadable(() => import("./pages/manage/AssignCourse" /* webpackChunkName: "assigncourse" */))
const CourseBatch = loadable(() => import("./pages/manage/CourseBatch" /* webpackChunkName: "coursebatch" */))
const Tracks = loadable(() => import("./pages/manage/Tracks" /* webpackChunkName: "tracks" */))
const MarketTrack = loadable(() => import("./pages/manage/MarketTrack" /* webpackChunkName: "marketTrack" */))
const MarketTrackPreview = loadable(() => import("./pages/manage/MarketTrackPreview" /* webpackChunkName: "markettrackpreview" */))
const Subscriptions = loadable(() => import("./pages/manage/Subscriptions" /* webpackChunkName: "view" */))
const Order = loadable(() => import("./pages/manage/Order" /* webpackChunkName: "view" */))
const TrackingSheet = loadable(() => import("./pages/manage/TrackingSheet" /* webpackChunkName: "trackingsheet" */))
const PublishedPublicCourse = loadable(() => import("./pages/manage/PublishedPublicCourse" /* webpackChunkName: "publishedpubliccourse" */))
const Coupons = loadable(() => import("./pages/manage/Coupons" /* webpackChunkName: "courses" */))

//ACCOUNT
const Settings = loadable(() => import("./pages/account/Settings" /* webpackChunkName: "settings" */))
const Profile = loadable(() => import("./pages/account/Profile" /* webpackChunkName: "profile" */))
const Notifications = loadable(() => import("./pages/account/Notifications" /* webpackChunkName: "notifications" */))
const Acknowledge = loadable(() => import("./pages/account/Acknowledge" /* webpackChunkName: "acknowledge" */))
const OrderHistory = loadable(() => import("./pages/account/OrderHistory" /* webpackChunkName: "orderHistory" */))

//SURVEY
const SurveyPreview = loadable(() => import("./pages/survey/SurveyPreview" /* webpackChunkName: "surveypreview" */))
const SurveyView = loadable(() => import("./pages/survey/SurveyView" /* webpackChunkName: "surveyview" */))

//ADMIN
const Roles = loadable(() => import("./pages/admin/Roles" /* webpackChunkName: "roles" */))
const ResourcePermissions = loadable(() => import("./pages/admin/ResourcePermissions" /* webpackChunkName: "resourcepermissions" */))
const Invite = loadable(() => import("./pages/admin/Invite" /* webpackChunkName: "invite" */))
const Users = loadable(() => import("./pages/admin/Users" /* webpackChunkName: "users" */))
const Tenants = loadable(() => import("./pages/admin/Tenants" /* webpackChunkName: "tenants" */))
const AddSubscriptions = loadable(() => import("./pages/admin/AddSubscriptions" /* addsubscriptions: "add-subscriptions" */))

//ERROR
const AuthorizationRequired = loadable(() => import("./pages/error/AuthorizationRequired" /* webpackChunkName: "authreq" */))
const NotFound = loadable(() => import("./pages/error/NotFound" /* webpackChunkName: "notfound" */))
const ServerError = loadable(() => import("./pages/error/ServerError" /* webpackChunkName: "servererror" */))

//PUBLIC
const PublicProposal = loadable(() => import("./pages/public/Proposal" /* webpackChunkName: "publicproposal" */))
const Verify = loadable(() => import("./pages/public/Verify" /* webpackChunkName: "verify" */))

//COURSES
const View = loadable(() => import("./pages/courses/View" /* webpackChunkName: "view" */))
const CoursePreview = loadable(() => import("./pages/courses/CoursePreview" /* webpackChunkName: "preview" */))
const TrackPreview = loadable(() => import("./pages/courses/TrackPreview" /* webpackChunkName: "track-preview" */))
const JoinActivity = loadable(() => import("./pages/manage/JoinActivity" /* webpackChunkName: "join-activity" */))
const CourseDetails = loadable(() => import("./pages/courses/CourseDetails" /* webpackChunkName: "course-details" */))

//Zoom
const Zoom = loadable(() => import("./pages/zoom/Zoom" /* webpackChunkName: "view" */))

//Contents
const Contents = loadable(() => import("./pages/contents/Contents" /* webpackChunkName: "contents" */))

//Tracks
const ViewTrack = loadable(() => import("./pages/tracks/ViewTrack.jsx" /* webpackChunkName: "viewtrack" */))

const routesMapping = {
    // Admin
    "/admin": {
        path: "/admin",
        element: <Home fallback={<LoadingScreen />} />,
    },
    "/admin/roles": {
        path: "roles",
        element: <Roles fallback={<LoadingScreen />} />,
    },
    "/admin/resource-permissions": {
        path: "resource-permissions",
        element: <ResourcePermissions fallback={<LoadingScreen />} />,
    },
    "/admin/invite": {
        path: "invite",
        element: <Invite fallback={<LoadingScreen />} />,
    },
    "/admin/users": {
        path: "users",
        element: <Users fallback={<LoadingScreen />} />,
    },
    "/admin/tenants": {
        path: "tenants",
        element: <Tenants fallback={<LoadingScreen />} />,
    },
    "/admin/add-subscription": {
        path: "add-subscription",
        element: <AddSubscriptions fallback={<LoadingScreen />} />,
    },

    //Manage
    "/manage": {
        path: "/manage",
        element: <AuthGaurd><Home fallback={<LoadingScreen />} /></AuthGaurd>,
    },
    "/manage/grouptherapies": {
        path: "grouptherapies",
        element: <PatientGroup fallback={<LoadingScreen />} />,
    },
    "/manage/sagrouptherapies": {
        path: "sagrouptherapies",
        element: <PatientGroupSA fallback={<LoadingScreen />} />,
    },
    "/manage/clinics": {
        path: "clinics",
        element: <Clinic fallback={<LoadingScreen />} />,
    },
    "/manage/LMS": {
        path: "LMS",
        element: <Lms fallback={<LoadingScreen />} />,
    },
    "/manage/patients": {
        path: "patients",
        element: <Patient fallback={<LoadingScreen />} />,
    },
    "/manage/activityattendee": {
        path: "activityattendee",
        element: <ActivityAttendee fallback={<LoadingScreen />} />,
    },
    "/manage/courseattendee":{
        path: "courseattendee",
        element: <CourseAttendee fallback={<LoadingScreen />} />,
    },
    "/manage/activityohreport":{
        path: "activityohreport",
        element:<ActivityOhReport fallback={<LoadingScreen />} />,
    },
    "/manage/clinicians": {
        path: "clinicians",
        element: <Clinician fallback={<LoadingScreen />} />,
    },
    "/manage/casurveys": {
        path: "casurveys",
        element: <SurveyCA fallback={<LoadingScreen />} />,
    },
    "/manage/sasurveys": {
        path: "sasurveys",
        element: <SurveySA fallback={<LoadingScreen />} />,
    },
    "/manage/surveygroups": {
        path: "surveygroups",
        element: <SurveyGroup fallback={<LoadingScreen />} />,
    },
    "/manage/researchinstitutes": {
        path: "researchinstitutes",
        element: <ResearchInstitution fallback={<LoadingScreen />} />,
    },
    "/manage/researchers": {
        path: "researchers",
        element: <Researcher fallback={<LoadingScreen />} />,
    },
    "/manage/contentcreators": {
        path: "contentcreators",
        element: <ContentCreator fallback={<LoadingScreen />} />,
    },
    "/manage/activities": {
        path: "activities",
        element: <Courses fallback={<LoadingScreen />} />,
    },
    "/manage/review-activities": {
        path: "review-activities",
        element: <ReviewARFActivities fallback={<LoadingScreen />} />,
    },
    "/manage/courses": {
        path: "courses",
        element: <PublishedCourse fallback={<LoadingScreen />} />,
    },
    "/manage/coursebatches": {
        path: "coursebatches",
        element: <CourseBatch fallback={<LoadingScreen />} />,
    },
    "/manage/tracks": [{
        path: "tracks",
        element: <Tracks fallback={<LoadingScreen />} />,
    },
    {
        path: "markettrack",
        element: <MarketTrack fallback={<LoadingScreen />} />,
    }
    ],
    "/manage/assigncourses": {
        path: "assigncourses",
        element: <AssignCourse fallback={<LoadingScreen />} />,
    },
    "/manage/marketactivity": {
        path: "marketactivity",
        element: <MarketActivity fallback={<LoadingScreen />} />,
    },
    "/manage/review-marketactivity": {
        path: "review-marketactivity",
        element: <ReviewMarketActivity fallback={<LoadingScreen />} />,
    },
    "/manage/trackingsheet": {
        path: "trackingsheet",
        element: <TrackingSheet fallback={<LoadingScreen />} />,
    },
    "/manage/proposals": {
        path: "proposals",
        element: <Proposal fallback={<LoadingScreen />} />,
    },
    "/manage/markettrack": {
        path: "markettrack",
        element: <MarketTrack fallback={<LoadingScreen />} />,
    },
    "/manage/subscriptions": {
        path: "subscriptions",
        element: <Subscriptions fallback={<LoadingScreen />} />,
    },
    "/manage/order": {
        path: "order",
        element: <Order fallback={<LoadingScreen />} />,
    },
    "/manage/coupons": {
        path: "coupons",
        element: <Coupons fallback={<LoadingScreen />} />,
    },

    //Courses
    "/courses": {
        path: "/courses",
        element: <AuthGaurd><Home fallback={<LoadingScreen />} /></AuthGaurd>,
    },

    // Dashboard 
    "/dashboard": {
        path: "/dashboard",
        element: <AuthGaurd><Home fallback={<LoadingScreen />} /></AuthGaurd>,
        children: [
            {
                path: "",
                element: <Dashboard fallback={<LoadingScreen />} />,
            }
        ]
    },
}

const AccountRoutes = [
    {
        path: '/account',
        element: <AuthGaurd><Home fallback={<LoadingScreen />} /></AuthGaurd>,
        children: [
            {
                path: "profile",
                element: <Profile fallback={<LoadingScreen />} />,
            },
            {
                path: "settings",
                element: <Settings fallback={<LoadingScreen />} />,
            },
            {
                path: "notifications",
                element: <Notifications fallback={<LoadingScreen />} />,
            },
            {
                path: "orders-history",
                element: <OrderHistory fallback={<LoadingScreen />} />,
            },
        ]
    },
    // {
    //     path: '/manage',
    //     element: <AuthGaurd><Home fallback={<LoadingScreen />} /></AuthGaurd>,
    //     children: [
    //         {
    //             path: "order",
    //             element: <Order fallback={<LoadingScreen />} />,
    //         }
    //     ]
    // },
    {
        path: '/manage',
        element: <Home fallback={<LoadingScreen />} />,
        children: [
            {
                path: "marketactivity-preview",
                element: <MarketActivityPreview fallback={<LoadingScreen />} />,
            },
            {
                path: "markettrack-preview",
                element: <MarketTrackPreview fallback={<LoadingScreen />} />,
            }
        ]
    },
    {
        path: '/survey',
        element: <AuthGaurd><Home fallback={<LoadingScreen />} /></AuthGaurd>,
        children: [
            {
                path: "view",
                element: <SurveyView fallback={<LoadingScreen />} />,
            },
            {
                path: "preview",
                element: <SurveyPreview fallback={<LoadingScreen />} />,
            }
        ]
    },
    {
        path: '/courses',
        element: <AuthGaurd><Home fallback={<LoadingScreen />} /></AuthGaurd>,
        children: [
            {
                path: "view",
                element: <View fallback={<LoadingScreen />} />,
            },
        ]
    },
    {
        path: '/tracks',
        element: <AuthGaurd><Home fallback={<LoadingScreen />} /></AuthGaurd>,
        children: [
            {
                path: "view",
                element: <ViewTrack fallback={<LoadingScreen />} />,
            },
        ]
    },
    {
        path: '/zoom',
        element: <Zoom fallback={<LoadingScreen />} />
    },
    {
        path: '/contents',
        element: <Home fallback={<LoadingScreen />} />,
        children: [
            {
                path: "",
                element: <Contents fallback={<LoadingScreen />} />,
            },
        ]
    }
];

const AccountsAcknowledge = [
    {
        path: '/account',
        element: <AuthGaurd><Home fallback={<LoadingScreen />} /></AuthGaurd>,
        children: [
            {
                path: 'acknowledge',
                element: <Acknowledge fallback={<LoadingScreen />} />,
            },
        ]
    },
]

const JoinActivityRoute = [
    {
        path: '/manage/activities',
        element: <AuthGaurd><Home fallback={<LoadingScreen />} /></AuthGaurd>,
        children: [
            {
                path: 'join',
                element: <JoinActivity fallback={<LoadingScreen />} />,
            },
        ]
    },
]

const AuthRoutes = [
    {
        path: "/auth/login",
        element: <Login fallback={<LoadingScreen />} />,
    },
    {
        path: "/auth/forgetPassword",
        element: <ForgetPassword fallback={<LoadingScreen />} />,
    },
    {
        path: "/auth/resetPassword",
        element: <ResetPassword fallback={<LoadingScreen />} />,
    },
    {
        path: "/auth/signup",
        element: <SignUp fallback={<LoadingScreen />} />,
    },
];

const ErrorRoutes = [
    {
        path: '/error',
        element: <Home fallback={<LoadingScreen />} />,
        children: [
            {
                path: "404",
                element: <NotFound fallback={<LoadingScreen />} />,
            },
            {
                path: "401",
                element: <AuthorizationRequired fallback={<LoadingScreen />} />,
            },
            {
                path: "500",
                element: <ServerError fallback={<LoadingScreen />} />,
            },
        ]
    },

];

const Public = [
    //public
    {
        path: "/proposal",
        element: <PublicProposal fallback={<LoadingScreen />} />,
    },
    {
        path: '/published-courses',
        element: <Home fallback={<LoadingScreen />} />,
        children: [
            {
                path: "",
                element: <PublishedPublicCourse fallback={<LoadingScreen />} />,
            },
        ]
    },
    {
        path: '/auth',
        element: <Home fallback={<LoadingScreen />} />,
        children: [
            {
                path: "verify",
                element: <Verify fallback={<LoadingScreen />} />,
            },
        ]
    },
    {
        path: '/course',
        element: <Home fallback={<LoadingScreen />} />,
        children: [
            {
                path: "preview",
                element: <CoursePreview fallback={<LoadingScreen />} />,
            },
            {
                path: "details",
                element: <CourseDetails fallback={<LoadingScreen />} />,
            },
        ]
    },
    {
        path: '/track',
        element: <Home fallback={<LoadingScreen />} />,
        children: [
            {
                path: "details",
                element: <TrackPreview fallback={<LoadingScreen />} />,
            }
        ]
    },
    {
        path: '/manage',
        element: <Home fallback={<LoadingScreen />} />,
        children: [
            {
                path: "order",
                element: <Order fallback={<LoadingScreen />} />,
            }
        ]
    },
];

const NotFound404 = [
    {
        path: "/*",
        element: <Navigate to="/error/404" />,
    },
];

const DefaultParentDashboard = [
    {
        path: "/dashboard",
        element: <AuthGaurd><Home fallback={<LoadingScreen />} /></AuthGaurd>,
        children: [
            {
                path: "",
                element: <Dashboard fallback={<LoadingScreen />} />,
            }
        ]
    },
    {
        path: "/",
        element: <Navigate to="/dashboard" />,
    },
];

const DefaultParentLogin = [
    {
        path: "/",
        element: <Navigate to="/published-courses" />,
    },
    {
        path: "/*",
        element: <Navigate to="/auth/login" />,
    },
];


const DefaultLogin = [
    {
        path: "/",
        element: <Navigate to="/auth/login" />,
    },
    {
        path: "/*",
        element: <Navigate to="/auth/login" />,
    },
];

const SubscriptionPaymentRoute = [
    ...AuthRoutes,
    ...Public,
    {
        path: "/subscription-payment",
        element: <SubscriptionPayment fallback={<LoadingScreen />} />
    },
    {
        path: "/",
        element: <Navigate to="/subscription-payment" />,
    },
    {
        path: "/*",
        element: <Navigate to="/subscription-payment" />,
    },
]

const TitleRouteMapping = {
    "Manage": routesMapping["/manage"],
    // "Reports": routesMapping[""],
    "Admin": routesMapping["/admin"],
    "Account": routesMapping["/account"]
}

const getAllChildrenPaths = (childResources, paths = []) => {
    childResources.forEach((currentResource) => {
        if (currentResource?.path) paths.push(currentResource.path);
        if (currentResource?.children?.length) getAllChildrenPaths(currentResource?.children, paths);
    })
}

export const getRoutes = (resources, auth) => {
    if (!auth?.isSuperAdmin && !!(
        auth.details?.jwtToken &&
        !auth.details?.isSubscriptionPaymentDone &&
        auth.details?.clientSubscriptionPlans?.length
    )) {
        return SubscriptionPaymentRoute;
    }
    let filteredRoutes = [];
    if (resources?.items?.length) {
        for (const currentResource of resources?.items) {
            let resource = {
                children: []
            };
            if (currentResource?.path && routesMapping[currentResource.path]) {
                resource = {
                    ...routesMapping[currentResource.path],
                    children: [],
                }
            } else if (TitleRouteMapping[currentResource?.title]) {
                resource = {
                    ...TitleRouteMapping[currentResource.title],
                    children: [],
                }
            }
            if (currentResource?.children?.length) {
                const paths = [];
                getAllChildrenPaths(currentResource?.children, paths);
                paths.forEach((currentPath) => {
                    if (Array.isArray(routesMapping[currentPath])) {
                        return resource.children.push(...routesMapping[currentPath])
                    } else if (routesMapping[currentPath]) {
                        resource.children.push(routesMapping[currentPath])
                    }
                });
            }
            if (resource?.path) {
                filteredRoutes.push(resource);
            }
        }
        filteredRoutes.push(
            ...AccountRoutes,
            ...NotFound404,
            //...(auth?.isSuperAdmin ? [] : AccountsAcknowledge)
        )
    }
    if (!auth?.isSuperAdmin && !resources?.items?.length && !auth?.details?.jwtToken) {
        if (window.location.host.includes('drtruitt')) {
            filteredRoutes.push(...DefaultLogin);
        } else {
            filteredRoutes.push(...DefaultParentLogin);
        }

    }
    if (auth?.isSuperAdmin || resources?.items?.length || auth?.details?.jwtToken) {
        filteredRoutes.push(
            ...DefaultParentDashboard,
        );
    }

    filteredRoutes.push(
        ...ErrorRoutes,
        ...Public,
        ...AuthRoutes,
        ...AccountRoutes,
        ...AccountsAcknowledge,
        ...JoinActivityRoute
    )
    // console.log("filteredRoutes", filteredRoutes)
    return filteredRoutes;
}