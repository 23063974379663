import { types } from "mobx-state-tree";
import { PatientGroup, PatientGroupSA } from "./patientGroup";
import {
	Clinic,
	PracticeType,
	State,
	Country,
	Location,
	ResearchPartner,
} from "./clinic";
import { Clinician, License, Certification } from "./clinician";
import { Auth } from "./auth";
import {
	Survey,
	Factor,
	SurveyRange,
	SurveyCA,
	SurveySA,
	SurveyStats,
} from "./survey";
import { SurveyGroup } from "./surveyGroup";
import { Lms } from "./lms";
import {
	Patient,
	PatientDiagnosis,
	PatientMedicalManagement,
	PatientOtherSupport,
	PatientPreviousHealth,
	PatientFamilyHistory,
	PatientSubstanceUse,
} from "./patient";
import {
	ResearchInstitution,
	AdminContact,
	LeadResearcher,
	Prefix,
	ResearcherNameList,
	Researchers,
} from "./researchInstitution";
import {
	Roles,
	Invite,
	Resource,
	Users,
	AppleHealth,
	CertificateConsultant,
	Tenant,
} from "./admin";
import { ContentCreator, ContentProducer } from "./contentcreator";
import {
	Degree,
	DeliveryMethod,
	Curriculum,
	ICD,
	BiologicalSex,
	Gender,
	RelationshipStatus,
	OccupationalHistory,
	Designation,
	FamilyMember,
	LivingSituation,
	MedicalHistory,
	Medication,
	MentalHealthProvider,
	OtherSupport,
	Race,
	Substance,
	TherapeuticSetting,
	MilitaryBranch,
	MilitaryDischarge,
	EducationHistory,
	EducationHistoryHighestLevel,
	CurrentMilitaryStatus,
	TherapeuticProvider,
	FactorFormula,
	SkillLevel,
	Language,
	FinancialRelationship,
} from "./common";
import {
	CE,
	Courses,
	CourseStatus,
	Credit,
	Feedback,
	CourseRoles,
	IntendedAudience,
	CoursePreview,
	CoursePublish,
	CourseAssign,
	CourseBatch,
	Track,
	CourseView,
	TrackPreview,
	TrackPublish,
	TrackingSheet,
	TrackCertificationVideo,
	ReviewCourses,
	CoursePresenter,
	CE_Track,
} from "./courses";
import { Proposal } from "./proposal";
import { Profile, Notification, Settings } from "./account";
import { OrderItems, OrderHistoryItems, OrdersReport } from "./order";
import { WishlistItems } from "./wishlist";
import { Subscription } from "./subscription";
import { enqueueSnackbar } from "notistack";
import { Coupons } from "./coupons";
import { configure } from "mobx";
import { ActivityAttendee } from "./activityattendee";
import { CourseAttendee } from "./courseattendee";

configure({ isolateGlobalState: true });

const RootModel = types.model({
	auth: Auth,
	tenant: Tenant,
	invite: Invite,
	roles: Roles,
	settings: Settings,
	patientGroup: PatientGroup,
	patientGroupSA: PatientGroupSA,
	clinic: Clinic,
	practiceType: PracticeType,
	state: State,
	country: Country,
	location: Location,
	researchPartner: ResearchPartner,
	clinician: Clinician,
	license: License,
	certification: Certification,
	surveyGroup: SurveyGroup,
	survey: Survey,
	researchInstitution: ResearchInstitution,
	adminContact: AdminContact,
	leadResearcher: LeadResearcher,
	prefix: Prefix,
	researcherNameList: ResearcherNameList,
	researchers: Researchers,
	contentCreator: ContentCreator,
	contentProducer: ContentProducer,
	course: Courses,
	reviewCourse: ReviewCourses,
	ce: CE,
	ce_track:CE_Track,
	courseStatus: CourseStatus,
	credit: Credit,
	intendedAudience: IntendedAudience,
	factor: Factor,
	surveyRange: SurveyRange,
	casurvey: SurveyCA,
	sasurvey: SurveySA,
	surveyStats: SurveyStats,

	//course
	feedback: Feedback,
	courseRoles: CourseRoles,
	coursePreview: CoursePreview,
	coursePublish: CoursePublish,
	courseAssign: CourseAssign,
	courseBatch: CourseBatch,
	courseView: CourseView,
	trackingSheet: TrackingSheet,
	coursePresenter: CoursePresenter,

	//track
	track: Track,
	trackPreview: TrackPreview,
	trackPublish: TrackPublish,
	trackCertificationVideo: TrackCertificationVideo,

	//patient
	patient: Patient,
	patientMedicalManagement: PatientMedicalManagement,
	patientPreviousHealth: PatientPreviousHealth,
	patientOtherSupport: PatientOtherSupport,
	patientDiagnosis: PatientDiagnosis,
	patientFamilyHistory: PatientFamilyHistory,
	patientSubstanceUse: PatientSubstanceUse,

	//proposal
	proposal: Proposal,

	//admin
	resource: Resource,
	users: Users,
	//notification
	notification: Notification,

	//profile
	profile: Profile,

	//order
	orderItems: OrderItems,
	orderHistoryItems: OrderHistoryItems,
	orderReport:OrdersReport,

	//common
	degree: Degree,
	deliveryMethod: DeliveryMethod,
	curriculum: Curriculum,
	icd: ICD,
	biologicalSex: BiologicalSex,
	gender: Gender,
	relationshipStatus: RelationshipStatus,
	occupationalHistory: OccupationalHistory,
	designation: Designation,
	familyMember: FamilyMember,
	livingSituation: LivingSituation,
	medicalHistory: MedicalHistory,
	medication: Medication,
	mentalHealthProvider: MentalHealthProvider,
	otherSupport: OtherSupport,
	race: Race,
	substance: Substance,
	therapeuticSetting: TherapeuticSetting,
	militaryBranch: MilitaryBranch,
	militaryDischarge: MilitaryDischarge,
	educationHistory: EducationHistory,
	educationHistoryHighestLevel: EducationHistoryHighestLevel,
	currentMilitaryStatus: CurrentMilitaryStatus,
	therapeuticProvider: TherapeuticProvider,
	factorFormula: FactorFormula,
	skillLevel: SkillLevel,
	language: Language,
	financialRelationship: FinancialRelationship,

	// wishlist
	wishlistItems: WishlistItems,

	//subscription
	subscription: Subscription,

	appleHealth: AppleHealth,
	lms: Lms,
	certificateConsultant: CertificateConsultant,
	coupons: Coupons,
	activityAttendee: ActivityAttendee,
	courseAttendee:CourseAttendee,
});

const notifier = {
	enqueue(msg, variant) {
		enqueueSnackbar(msg, { variant: variant });
	},
};

export const rootStore = RootModel.create(
	{
		profile: { general: null, cv: null, bio: null },
		tenant: {},
		auth: { details: null },
		settings: {},
		invite: {},
		roles: { items: [] },
		patientGroup: { items: [] },
		patientGroupSA: { items: [] },
		clinic: { items: [] },
		practiceType: { items: [] },
		state: { items: [] },
		country: { items: [] },
		location: { items: [] },
		researchPartner: { items: [] },
		clinician: { items: [] },
		license: { items: [] },
		certification: { items: [] },
		surveyGroup: { items: [] },
		survey: { items: [] },
		researchInstitution: { items: [] },
		adminContact: { items: [] },
		leadResearcher: { items: [] },
		prefix: { items: [] },
		researcherNameList: { items: [] },
		researchers: { items: [] },
		contentCreator: { items: [] },
		contentProducer: { items: [] },
		course: { items: [] },
		reviewCourse: { items: [] },
		ce: {},
		ce_track: {},
		courseStatus: { items: [] },
		credit: { items: [] },
		intendedAudience: { items: [] },
		factor: { items: [] },
		surveyRange: { items: [] },
		casurvey: { items: [] },
		sasurvey: { items: [] },
		surveyStats: { items: [] },

		//course
		feedback: { history: [] },
		courseRoles: { users: [] },
		coursePreview: { details: null },
		coursePublish: { items: [] },
		courseAssign: { items: [] },
		courseBatch: { items: [] },
		courseView: { details: null },
		trackingSheet: { items: [] },
		coursePresenter: {items : []},

		//track
		track: { items: [] },
		trackPreview: { details: null, beginTrackJourney: null, presenterHeadshots: [] },
		trackPublish: { items: [] },
		trackCertificationVideo: {},
		//patient
		patient: { items: [] },
		patientMedicalManagement: { items: [] },
		patientPreviousHealth: { items: [] },
		patientOtherSupport: { items: [] },
		patientDiagnosis: { items: [] },
		patientFamilyHistory: { items: [] },
		patientSubstanceUse: { items: [] },

		//proposal
		proposal: { items: [] },

		//admin
		resource: { items: [] },
		users: { items: [] },

		//notification
		notification: { items: [] },

		//order
		orderItems: { items: [] },
		orderHistoryItems: { orders: [] },
		orderReport: { allOrders: [] },

		//common
		degree: { items: [] },
		deliveryMethod: { items: [] },
		curriculum: { items: [] },
		icd: { items: [] },
		biologicalSex: { items: [] },
		gender: { items: [] },
		relationshipStatus: { items: [] },
		occupationalHistory: { items: [] },

		designation: { items: [] },
		familyMember: { items: [] },
		livingSituation: { items: [] },
		medicalHistory: { items: [] },
		medication: { items: [] },
		mentalHealthProvider: { items: [] },
		otherSupport: { items: [] },
		race: { items: [] },
		substance: { items: [] },
		therapeuticSetting: { items: [] },
		militaryBranch: { items: [] },
		militaryDischarge: { items: [] },
		educationHistory: { items: [] },
		educationHistoryHighestLevel: { items: [] },
		currentMilitaryStatus: { items: [] },
		therapeuticProvider: { items: [] },
		factorFormula: { items: [] },
		skillLevel: { items: [] },
		language: { items: [] },
		financialRelationship: { items: [] },

		//wishlist
		wishlistItems: { items: [] },

		// subscription
		subscription: { items: [] },

		appleHealth: { items: [] },

		lms: {},
		certificateConsultant: {},
		coupons: { items: [] },
		activityAttendee: {},
		courseAttendee: {},
	},
	{ notifier }
);


