import { types, flow, getEnv, applySnapshot, destroy } from "mobx-state-tree";
import { getRequestOptions } from "../../utils/RequestOptions";
import {
    CONTENT_TYPE,
    ROOT_URL,
    API_ERROR_MESSAGE,
    RESPONSE_STATUS,
} from "../../constants";
import { fetchApi } from "../../utils/CustomFetch";
const CourseList = types.model({
    trackId: types.maybeNull(types.number),
    trackName: types.maybeNull(types.string),
    trackSNo: types.maybeNull(types.string),
})

const CourseAttendeeItem = types.model({
    trackId: types.maybeNull(types.number),
    trackSNo: types.maybeNull(types.string),
    trackName: types.maybeNull(types.string),
    trackType: types.maybeNull(types.string),
    attendeeId: types.maybeNull(types.number),
    attendeeName: types.maybeNull(types.string),
    attendeeEmail: types.maybeNull(types.string),
    attendeePhone: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    isDeactivate: types.maybeNull(types.boolean),
    orderId: types.maybeNull(types.string),
})
export const CourseAttendee = types.model({
    items: types.array(CourseAttendeeItem),
    contentType: types.optional(types.number, CONTENT_TYPE.LIST),
    courses:types.array(CourseList),
    filterData: types.optional(CourseAttendeeItem, {})
}).actions((self) => {
    return {
        load: flow(function* load(data) {
            try {
                const response = yield fetchApi(
                    `${ROOT_URL}/api/Course/GetTrackAttendeeReport?trackId=${
                        data.trackId ? data.trackId : ""
                    }&trackSNo=${
                        data.trackSNo ? data.trackSNo : ""
                    }&trackType=${
                        data.trackType ? data.trackType : ""
                    }`,
                    getRequestOptions("GET", null)
                );
                const json = yield response.json();
                if (response.status === RESPONSE_STATUS.SUCCESS) {
                    applySnapshot(self.items, json);
                    applySnapshot(self.filterData, data);
                } else {
                    getEnv(self).notifier.enqueue(json?.message, "error");
                }
            } catch (error) {
                getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
            }
        }),
        loadCourses: flow(function* loadCourses () {
            try {
                const response = yield fetchApi(
                    `${ROOT_URL}/api/Course/GetTrackListForAttendee`,
                    getRequestOptions("GET", null)
                );
                const json = yield response.json();
                if (response.status === RESPONSE_STATUS.SUCCESS) {
                    self.courses = json;
                } else {
                    getEnv(self).notifier.enqueue(json?.message, "error");
                }
            } catch (error) {
                getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
            }
        }),
        updateDeactivateCourseAttendee: flow(function* updateDeactivateCourseAttendee(data) {
            try {
                const response = yield fetchApi(
                    `${ROOT_URL}/api/Course/updateDeactivateTrackAttendee`,
                    getRequestOptions("POST", data)
                );

                const json = yield response.json();
                if (response.status === 200) {
                    if (json.status !== 0) {
                        getEnv(self).notifier.enqueue(json.error, "error");
                    } else {
                        getEnv(self).notifier.enqueue(
                            "Record Successfully Updated!!",
                            "success"
                        );
                        self.load(self.filterData);
                    }
                } else {
                    getEnv(self).notifier.enqueue(json.error, "error");
                }
            } catch (error) {
                getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
            }
        }),
        remove: flow(function* remove(recordData) {
            try {
                const attendee = self.items.find(
                    (item) => item.attendeeId === recordData.attendeeId && item.trackId === recordData.trackId && item.trackSNo === recordData.trackSNo
                );
                const response = yield fetchApi(
                    `${ROOT_URL}/api/Course/UnassignTrackFromAttendee`,
                    getRequestOptions("POST", {
                        attendeeId: attendee.attendeeId,
                        trackId: attendee.trackId,
                        trackSNo: attendee.trackSNo,
                    })
                );
                const json = yield response.json();
                if (response.status === 200 && json.status === 0) {
                    
                    destroy(attendee);
                    getEnv(self).notifier.enqueue(
                        "Record Successfully Deleted!!",
                        "success"
                    );
                } else {
                    getEnv(self).notifier.enqueue(json?.error, "error");
                }
            } catch (e) {
                getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
            }
        }),
        assignUnassignCourseFromAttendee: flow(function* assignUnassignCourseFromAttendee(payload, callback=()=>{}) {
            try {
                const response = yield fetchApi(
                    `${ROOT_URL}/api/Course/AssignUnassignTrackFromAttendee`,
                    getRequestOptions("POST", payload)
                );
                const json = yield response.json();
                if (response.status === 200 && json.status === 0) {
                    getEnv(self).notifier.enqueue(
                        "Record Successfully Updated!!",
                        "success"
                    );
                    self.load(self.filterData);
                } else {
                    getEnv(self).notifier.enqueue(json?.error, "error");
                }
                callback();
            } catch (e) {
                getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
            }
        }),
        updateContentType(contentType) {
            self.contentType = contentType;
        },
    }
});